<template>
	<div class="page-single">
		<van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('邀请规则')" left-arrow @click-left="onClickLeft" >
		</van-nav-bar>
		<div class="page-banner">
            <img src="../../assets/pictures/page-banner3.jpg" alt="">
        </div>
        <div class="page-iframe">
			<div class="page-top"></div>
            <div class="page-body" v-html="html">
            </div>
			<div class="page-bottom"></div>
        </div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				active: 0,
				show:false,
				name:'',
				html: '',
				ynum: 0.0,
				cnum: 0.0,
				last_time: '0000-00-00 00:00:00'
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			df(v, i){
				let z = '0';
				let vv = parseFloat(v).toFixed(6).toString();
				let l = vv.length;
				for (let ii = l; ii < 10; ii++){
					vv = z + vv;
				}
				return vv.substring(i - 1, i);
			},
			getData() {
				this.$axios
						.get('/help/get_content?type=11')
						.then((res) => {
							this.html = res.data;
						});
			},
		},
		mounted() {
			this.getData();
		}
	}
</script>
<style scoped="scoped">
	.pagecard{
		background: #FFFFFF;
		min-height: 100vh;
		margin-top: 160px;
		border-radius: 25px 25px 0 0;
	}
	.mt-20{
		margin-top: 20px;
	}
	.text-2a{
		color: #2A58E5;
	}
	.text-7a8{
		color: #7A869A
	}
	.list{
		display: flex;
		line-height: 23px;
	}
	.lihei-24{
		line-height: 23px;
	}
	.yuan{
		width: 7px;
		height: 7px;
		border-radius: 10px;
		background: radial-gradient(circle, #53aeff, #ffffff);
		margin: 8px 13px 0 7px;
	}
	.flex-1{
		flex: 1;
	}
	.mt-50{
		margin-top: 50px;
	}
	.mt-10{
		margin-top: 10px;
	}
	.blue{
		padding: 8px 20px;
		border-radius: 80px;
		background: linear-gradient(90deg, #1A4FC3, #FFFFFF);
		color: #FFFFFF;
		margin-top: 35px;
	}
	.rule-table{
		background: #5774cd;
		color: #FFFFFF;
		margin-top: 20px;
	}
	.rule-table thead{
		background: #5774cd;
	}
	.rule-table td{
		border: 1px solid #FFFFFF;
	}
	.green{
		background: linear-gradient(90deg, #2FB487, #FFFFFF);
	}
	.reds{
		background: linear-gradient(90deg, #ff7375, #FFFFFF);
	}
	.text-green{
		color: #2FB487;
	}
	.nums{
		width: 19px;
		height: 19px;
		background: linear-gradient(90deg, #2FB487, #148D64);
		text-align: center;
		line-height: 19px;
		color: #FFFFFF;
		margin-right: 10px;
	}
	.red-fl{
		display: flex;
		align-items: center;
		color: #FF6960;
	}
	.red-y{
		background: radial-gradient(circle, #FF6960, #ffffff);
		margin: 0 10px 0 0;
	}
	.bott{
		color: #2A58E5;
		margin-top: 50px;
	}
</style>